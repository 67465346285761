.benefits {
  padding: $vs 0 calc(#{$vs} * 2);

  .row {
    padding: calc(#{$vs} * 4) 0;
    @include respond-down(sm) {
      padding: calc(#{$vs} * 2) 0;
    }

    &:not(:last-of-type) {
      margin: 0 auto calc(#{$vs} * 5);
      @include respond-down(sm) {
        margin: 0 auto calc(#{$vs});
      }
    }
  }

  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include respond-down(sm) {
      justify-content: flex-start;
      flex-direction: column;
    }

    &.odd {
      @include respond-up(md) {
        flex-direction: row-reverse;
      }
    }

    .text {
      width: 33.33334%;
      @include respond-down(sm) {
        width: 100%;
      }

      .icon {
        display: flex;
        height: $vh;
        align-items: center;

        .svg-icon {
          height: 100%;
        }
      }

      .title {
        margin: $vs 0 0;
        line-height: $vh;
      }

      .description {
        margin: $vs 0 0;
        font-weight: $medium;
        @include f(17px);
        color: $black;
        line-height: calc(30 / 17);
      }
    }

    .image {
      display: flex;
      width: 53%;
      height: calc(5 * #{$vh} + 4 * #{$vs});
      @include respond-down(sm) {
        margin-top: calc(#{$vs} * 2);
        width: 100%;
      }

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}