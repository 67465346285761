h1, h2, h3, h4, h5, h6 {
  font-family: $h-font;
  font-weight: $semi;
}

$h1-size: 80px;
$h2-size: 60px;
$h3-size: 40px;
$h4-size: 27px;
$h5-size: 22px;
$h6-size: 20px;

h1 {
  font-size: calc(var(--fs) * #{$h1-size});
  font-weight: $bold;
  @include respond-down(xs) {
    font-size: 31px;
  }
}

h2 {
  font-size: calc(var(--fs) * #{$h2-size});
  @include respond-down(xs) {
    font-size: 25px;
  }
}

h3 {
  font-size: calc(var(--fs) * #{$h3-size});
  @include respond-down(xs) {
    font-size: 22px;
  }
}

h4 {
  font-size: calc(var(--fs) * #{$h4-size});
  @include respond-down(xs) {
    font-size: 20px;
  }
}

h5 {
  font-size: calc(var(--fs) * #{$h5-size});
  @include respond-down(xs) {
    font-size: 18px;
  }
}

h6 {
  font-size: calc(var(--fs) * #{$h6-size});
  @include respond-down(xs) {
    font-size: 17px;
  }
}

@mixin f($size) {
  @if $size {
    font-size: calc(var(--fs) * #{$size});
  } @else {
    font-size: calc(var(--fs) * 16px);
  }
}