.manage {
  padding: calc(#{$vh} * 2) 0 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    .text {
      max-width: 728px;
      font-weight: $semi;
      @include f(26px);
      color: $black;
      text-align: center;
      line-height: calc(45 / 26);
    }

    .hand-w-app {
      display: block;
      margin: calc(#{$vh} * 2) auto 0 calc(#{$vs} * 7);
      width: 772px;
      height: calc(calc(#{$vh} * 6) + calc(#{$vs} * 5));
      background: url('../images/index/hand-w-app.png') no-repeat;
      background-size: contain;
      @include respond-down(sm){
        margin-left: 0;
      }
      @include respond(sm) {
        width: 500px;
      }
      @include respond(xs) {
        width: 100%;
        height: 210px;
      }
    }
  }
}