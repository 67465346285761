.os3 {
  * {
    position: relative;
  }

  h1 {
    font-weight: 200;
    font-size: 50px;
    width: 70%;
    text-align: center;
    margin:auto;
    strong {
      font-weight: 700;
    }

  }

  h2 {
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 27px;
    text-transform: uppercase;
    line-height: 1.26em;
  }

  h3 {
    margin: 1.67em 0 0 0;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.67em;
    text-transform: uppercase;
    &:first-child {
      margin:0;
    }
  }



  p {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: 500;
  }

  a {
    color: #8a2432;
  }

  a.c4btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 0 12.5px;
    margin: 0 0 10px 0;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-indent: 0;
    background-color: #e8e8e8;
    border-color: transparent;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: 0.1s;
    &:hover {
      color: #fff !important;
      background-color: #c32032 !important;
      border-color: transparent !important;

    }
  }

  a.c4btn.outline {
    color: #fff;
    line-height: calc(50px - 2px);
    background: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    align-items: center;
    justify-content: center;
    height: 50px;
    display: inline-flex;
    padding: 0 12.5px;
    margin: 0 0 10px 0;
    font-size: 15px;
    font-weight: 500;
    &.dark {
      color: #000;
      border-color: #000;
    }
    &:hover {
      color: #fff !important;
      background-color: #c32032 !important;
      border-color: transparent !important;

    }
  }
  form.dark input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="range"]) {
    background-color: rgba(255, 255, 255, 0.8);
  }

  a.c4btn.light-grey {
    color: #000;
    background-color: #ddddde;
  }

  small {
    display: inline-block;
    color: #75787b;
    font-size: 12px;
    line-height: 18px;
  }

  button {
    margin: auto;
  }


  .column {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }

  .full-screen {
    min-height: 100vh !important;
    width: 100% !important;
    overflow: hidden;
  }

  .bold {
    font-weight: 900;
  }

  .half-margin-bottom {
    margin-bottom: 25px;
  }
  .text-white {
    color: #ffffff;
  }
  .light-grey {
    background-color: #f6f6f7;
  }
  .padding {
    padding: 25px;
  }

  .padding-top {
    padding-top: 75px;
  }
  .padding-right {
    padding-right: 75px;
  }
  .padding-bottom {
    padding-bottom: 75px;
  }
  .padding-left {
    padding-left: 75px;
  }
  .half-padding-top {
    padding-top: 25px;
  }
  .half-padding-right {
    padding-right: 25px;
  }
  .half-padding-bottom {
    padding-bottom: 25px;
  }
  .half-padding-left {
    padding-left: 25px;
  }
  .row {
    padding:0;
  }
  .text-center {
    text-align: center;
  }

  .container {
    margin: 0 auto;
    padding-left: 25px;
    padding-right: 25px;
    width: 1170px;
    @include respond-down(lg){
      width: 100%;
    }
  }

  .white {
    background-color: #fff;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }


  .padding-50 {
    padding: 50px;
  }

  .bg-grey-10 {
    background-color: #edeeee;
  }

  .bg-grey-15 {
    background-color: #e5e6e6;
  }

  .shadow {
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
  }

  .block {
    display: block;
    width: 100%;
  }

  .vertically-center {
    width: 100%;
    position: absolute;
    top: 35%;
    //-webkit-transform: translateY(-50%);
    //-moz-transform: translateY(-50%);
    //-ms-transform: translateY(-50%);
    //transform: translateY(-50%);
  }

  .vertically-bottom {
    position: absolute;
    bottom: 15%;
    left: 8%;
  }

  .hidden {
    display: none;
  }

  .short-height {
    font-weight: 500;
  }

  .black {
    background-color: #000;
  }

  .aspect.one-to-one:before {
    padding-top: 100%;
  }

  .aspect:before {
    content: "";
    display: block;
    overflow: hidden;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  blockquote {
    display: block;
    position: relative;
    margin: 50px 50px 50px 50px;
    padding: 0px 0px 0px 40px;
    color: #8a2432;
    font-size: 20px;
    font-style: italic;
    line-height: 1.3em;
    &:before {
      content: "\201C";
      position: absolute;
      left: 0px;
      top: 10px;
      font-family: 'Georgia','Garamond Pro','Palatino Linotype','Book Antiqua';
      color: #8a2432;
      font-style: normal;
      font-weight: 300;
      font-size: 80px;
    }
  }
  blockquote  cite {
    display: block;
    margin-top: 12.5px;
    color: #75787b;
    font-family: 'Montserrat','Gotham','Arial',sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    &:before {
      content: "\2014\0020";
    }
  }



  @import "./hero";
  @import "./accordion";
  @import "./video";
  @import "./interface";
  @import "./download";
  @import "./questions";
  @import "./dangle-bober";
}