body {
  font-size: $body-font-size;
  font-family: $font;
  line-height: $line-height;
  background: $white-f6;
  overflow: auto;

  &.no-scroll{
    overflow: hidden;
  }
}

/**
  --vs - Row gutter height
  --vh - Row height
  --gw - Column gutter width
 */
:root {
  --vs: #{$rowGutterHeight};
  --vh: calc(var(--vs) * 3);
  --gw: calc(var(--vs) * 2);
  --fs: 1;
  @include respond(lg) {
    --vs: 19px;
    --fs: .8;
  }
  @include respond(md) {
    --vs: 17px;
    --fs: .7;
  }
  @include respond(sm) {
    --vs: 16px;
    --fs: .7;
  }
  @include respond(xs) {
    --vs: 15px;
    --fs: .65;
  }
}

/**
  Font anti-aliasing
 */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $white;

  &,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }

  &.hover-red {
    transition: color linear .1s;
    &:hover {
      color: $red !important;
    }
  }
}

/**
    Mixins
 */
@mixin upper() {
  text-transform: uppercase;
}

.upper, .uppercase {
  @include upper();
}


.c-white {
  color: $white !important;
}

.c-red {
  color: $red !important;
}

.bg-white {
  background: $white !important;
}

.bg-red {
  background: $red !important;
}

.underline {
  text-decoration: underline !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.red-line {
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: $red;
  width: 50px;
  height: 3px;
  margin-top: 15px;
}

.play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  //background: $white;
  width: calc(var(--vs) * 2 + var(--vh));
  height: calc(var(--vs) * 2 + var(--vh));
  //border-radius: 50%;
  padding: 0;
  background-color: transparent;

  &::before {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    //margin-left: 10px;
    //border-style: solid;
    //border-width: calc(var(--vs) * .86) 0 calc(var(--vs) * .86) calc(var(--vs) * 1.39534883721);
    //border-color: transparent transparent transparent $red;
  }

  &:hover {
    background-color: transparent;
    //background: $red;

    //&::before {
    //  border-color: transparent transparent transparent $white;
    //}
  }
}

.no-select {
  user-select: none !important;
}

.block-border {
  display: block;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, $grey9d 89.010416%, $grey9d 89.010416%, $white-f6 89.010416%);
}