.hero {
  display: flex;
  position: relative;
  margin-top: -100px;
  height: calc(#{$vs} * 9 + #{$vh} * 10);
  align-items: center;
  background-color: $black;
  background-position: center right;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../images/index/hdr_v3_0.jpg');
  overflow: hidden;
  @include respond-down(md) {
    background-size: cover;
  }

  .title {
    color: $white;
    max-width: 850px;
    line-height: 1;
    @include respond(lg) {
      max-width: 650px;
    }
  }

  .bottom-block {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    @include respond-down(sm) {
      padding-bottom: #{$vs};
    }
    @include respond-down(xs) {
      background: $t;
    }

    .row {
      display: flex;
      align-items: center;
      @include respond-down(xs) {
        flex-direction: column;
      }
    }

    .get-consult {
      display: block;
      position: relative;
      width: 100%;
      padding: calc(#{$vs} + 17px) calc(#{$vs} * 2);
      background: $white;


      &:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        background: $white;
        top: 0;
        bottom: 0;
        left: -9999px;
        width: 10000px;
        @include respond-down(sm) {
          content: none;
        }
      }

      p {
        max-width: 466px;
        font-weight: $semi;
        @include f(18px);
        color: $black;
        line-height: calc(30 / 18);
      }
    }

    .button {
      margin-top: auto;
    }
  }
}
