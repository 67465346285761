.index-page {
  @import "./hero";
  @import "./manage";
  @import "./what";
  @import "./video";
  @import "./benefits";
  @import "./partners";
  @import "./projects";
  padding-top: 88px;
  @include respond-down(lg){
    padding-top: 73px;
  }
}