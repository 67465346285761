body.is-loaded {
  .header-menu {
    display: block;
  }
}

.header-menu {
  position: fixed;
  opacity: 0;
  top: 87px;
  left: 100px;
  background: $white;
  z-index: 10;
  background: linear-gradient(to right, $greyed 55.416666666%, $greyed 55.416666666%, $white 44.583333334%);
  width: 100%;
  min-height: 700px;
  height: calc(100vh - 87px);
  transition: $easeOut;
  display: none;
  pointer-events: none;
  @include respond(lg) {
    top: 73px;
    height: calc(100vh - 73px);
  }
  @include respond(md) {
    top: 70px;
    height: calc(100vh - 70px);
  }
  @include respond(sm) {
    top: 68px;
    height: initial;
    min-height: calc(100vh - 68px);
  }
  @include respond(xs) {
    top: 66px;
    height: initial;
    min-height: calc(100vh - 66px);
  }
  @include respond-down(sm) {
    padding: 0;
    background: $white;
  }

  &.is-active {
    opacity: 1;
    left: 0;
    pointer-events: all;
  }

  .row {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    @include respond-down(sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 0px;
    }
  }

  .left {
    width: 50%;
    margin: auto 0;
    background: $greyed;
    @include respond-down(sm) {
      display: none;
      margin: calc(#{$vs} * 2) 0 0;
      width: 100%;
      max-width: 500px;
      background: $white;
    }
    @include respond-down(xs) {
      margin: calc(#{$vs}) 0 0;
    }

    .form-title {
      max-width: 471px;
      font-weight: $medium;
      @include f(24px);
      color: $black;
      line-height: calc(30 / 24);
      @include respond-down(sm) {
        @include f(30px);
      }
    }

    .form {
      margin-top: $vs;
      display: flex;
      flex-wrap: wrap;

      .form-field {
        width: calc(50% - 4px);
        margin: 4px;
        @include dontAnimateLabel;
        @include inputTransparentBorder;

        @include respond-up(md) {
          &:nth-child(odd) {
            margin-left: 0;
          }

          &:nth-child(even) {
            margin-right: 0;
          }
        }
        @include respond-down(sm) {
          width: 100%;
          margin: 0;
          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }

      .button {
        width: calc(50% - 4px);
        margin: 4px 0 0 4px;
        @include respond-down(sm) {
          width: 100%;
          margin: 10px 0 0;
        }
      }
    }

    &:before {
      content: "";
    }
  }

  .right {
    margin: auto 0 auto auto;
    @include respond-down(sm) {
      //margin: #{$vs} auto 0 0;
      width: 100%;
      margin: 0px;
      padding: 20px;
    }

    .list {
      display: flex;
      flex-direction: column;

      ul {
        overflow-y: scroll;
        height: 80vh;
        li{

          &.mm-heading{
            a {
              color: #858585;
              font-weight: bold;
              margin-top: 30px;
              display: block;

            }
          }

          a{
            color: #000;
            margin: 5px 0px;
            display: block;
          }
        }
      }

      .item {
        display: inline-block;
        color: $black;
        font-weight: $semi;
        line-height: calc(45 / 35);
        @include f(35px);
        white-space: nowrap;
        transition: opacity $easeOut;
        @include respond-down(xs) {
          @include f(25px);
        }

        &:hover {
          opacity: .7;
        }

        &:not(:last-child) {
          margin-bottom: $vs;
          @include respond-down(xs){
            margin-bottom: calc(.5 * #{$vs});
          }
        }
      }
    }
  }
}