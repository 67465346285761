.form-block {
  .tile-label {
    display: block;
    height: 50px;
    font-family: 'Montserrat','Gotham','Arial',sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: #000;
    line-height: 1.618em;
    text-align: center;
    background-color: #fff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    transition: 0.1s;
  }

  input[type="email"] {
    border: 1px solid #c1c1c1;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding: 21px;
    font-size: calc(var(--fs) * 14px);
    width: 100%;
    display: block;
  }

  #fieldfirstname, #fieldlastname, #fieldemail {
    border:none;
    margin-bottom: 10px;
    &::placeholder {
      font-weight: 700;
      color: #000000;
      text-transform: uppercase ;

    }
  }

  button {
    background-color: #424548;
    &:hover {
      background-color: #c32032
    }
  }

}