.video {
  .container-video {
    margin: auto;
    width: 50%;
  }
  .c4btn {
    width: auto;
  }

}
