$max-row-width: 1660;
$grid-columns: 12;
$grid: (
        xs: (
                from: 0,
                to: 599,
                gutter: 12,
                spacer: 19
        ),
        sm: (
                from: 600,
                to: 959,
                gutter: 20,
                spacer: 32
        ),
        md: (
                from: 960,
                to: 1263,
                gutter: 30,
                spacer: 48
        ),
        lg: (
                from:1264,
                to: 1599,
                gutter: 33,
                spacer: 53
        ),
        xl: (
                from: 1600,
                gutter: 50,
                spacer: 80
        )
);
/**
  Fonts
 */
$body-font-size: 16px;
$font: 'Montserrat', sans-serif;
$h-font: 'Montserrat', sans-serif;
$light: 300;
$medium: 500;
$semi: 600;
$bold: 700;

$line-height: 1.3;
/**
  Colors
 */
$t: transparent;
$red: #C42032;
$black: #000000;
$greyb8: #b8b8b8;
$grey9d: #9D9D9D;
$greyed: #ededed;
$nearly-white: #F5F3F0;
$white-f6: #F6FAFA;
$white: #fff;
$primary-color: #01c767;
$alert-color: #b50000;

/**
 Sizing
 */
$rowGutterHeight: 25px;
$rowHeight: $rowGutterHeight * 3;
$vs: var(--vs);
$vh: var(--vh);
$columnGutterWidth: 50px;
$columnWidth: 79px;
$gw: var(--gw);

/**
 Transitions
 */
$default-transition: none;
$easeOut: ease-out .2s;