.dangle-bomber {

  .dangle-bomber-image {
    height: calc(100vh - 100px);
    width: 80%;
    margin: 0 auto 25px 0;
    background-size: cover;
  }

  .dangle-bomber-image {
    margin: 0 0 25px auto;
  }
}