.hero {

  background-image: url('/static/images/index/hdr_v4_0.jpg');
  .full-screen, html:not(.mobile-browser).full-screen-desktop {
    min-height: 100vh !important;
    width: 100vw !important;
    overflow: hidden;
  }

}
