.video {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  //height: calc(#{$vs} * 8 + #{$vh} * 9);
  max-height: 866px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('/static/images/base/video_bg.jpg');
  //background-color: rgba(0, 0, 0, 0.9);
  overflow: hidden;

  @include respond(xs) {
    flex-direction: column;
  }

  .video-player-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.3%;
  }

  .video-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .play-button {
    position: absolute;
    top: calc(50% - (var(--vs) * 2 + var(--vh)) / 2);
    left: calc(50% - (var(--vs) * 2 + var(--vh)) / 2);
    z-index: 1;
    background: url("../svg/play.svg") no-repeat center center;
    background-size: contain;
    @include respond(xs) {
      top: calc(50% - 35px);
      //transform: translateY(-50%);
    }

    &:hover {
      background: url("../svg/play_hover.svg") no-repeat center center;
      background-size: contain;
    }
  }

  .caption {
    display: block;
    min-width: 35.2%;
    padding: calc(#{$vh}) calc(#{$vh} + #{$vs} * 2);
    position: absolute;
    right: 0;
    bottom: 0;
    @include f(19px);
    color: $black;
    background: $white;
    z-index: 1;
    @include respond(xs) {
      padding: calc(2 * #{$vs});
      position: static;
      width: 100%;
      text-align: center;
    }
  }

  .iframe-video-wrapper {
    display: none;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.3%;
    overflow: hidden;

    &.active {
      display: block;
    }
  }

  .iframe-video {
    position: absolute;
    height: 120%;
    top: -10%;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    z-index: 0;
    transition: all $easeOut;

    &.active {
      z-index: 10;
      opacity: 1;
      pointer-events: all;
    }
  }
}