.events {
  padding: 75px;


  .row {
    padding: 0;
  }
  .half-padding-bottom {
    padding-bottom: 25px;
  }


}