.header {
  display: flex;
  width: 100%;
  align-items: center;
  position: sticky;
  background: $black;
  top: 0;
  padding: calc(var(--vs) * .76) 0;
  z-index: 10;
  transition: background $easeOut;
  overflow: hidden;

  &.is-dark {
    background: $black;
  }

  .row {
    width: 100%;
  }

  .wrapper {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .logo {

    }

    .right-part {
      display: flex;
      align-items: center;

      .menu {
        @media screen and (max-width: 379px) {
          display: none;
        }

        .item {
          opacity: 1;
          color: $white;
          @include upper;
          @include f(15px);
          font-weight: $bold;
          transition: opacity $easeOut;

          &:not(:last-child) {
            margin-right: $gw;
          }

          &:hover {
            opacity: .8;
          }

          @include respond-down(sm) {
            font-size: 14px;
          }
          @include respond-down(xs){
            &:not(:last-child) {
              margin-right: 0;
            }
          }
        }

        .button {
          @include f(13px);
          white-space: nowrap;
          width: calc(var(--vs) * 2.6 + #{$vh} * 2);
          text-align: center;
          padding: 0;
        }
      }

      .hamburger {
        width: 35px;
        height: 45px;
        position: relative;
        transform: rotate(0deg);
        transition: all .3s ease-out;
        cursor: pointer;
        margin-left: $gw;

        &:before {
          content: "";
          display: block;
          position: absolute;
          opacity: 0;
          background: $white;
          top: -40px;
          bottom: -30px;
          left: -40px;
          right: -40px;
          height: auto;
          @include respond-down(lg) {
            left: calc(-1 * #{$vs});
            right: calc(-1 * #{$vs});
          }
        }

        .first {
          top: 10px;
          transform-origin: left center;
        }

        .second {
          top: 20px;
          transform-origin: left center;
        }

        .third {
          top: 30px;
          transform-origin: left center;
        }

        &.is-active {
          span {
            background: $black;
          }

          &:before, &:after {
            opacity: 1;
          }

          .first {
            transform: rotate(45deg);
            top: 9px;
            left: 5px;
          }

          .second {
            width: 0;
            opacity: 0;
          }

          .third {
            transform: rotate(-45deg);
            top: 33px;
            left: 4px;
          }
        }

        span {
          display: block;
          position: absolute;
          height: 1px;
          width: 100%;
          background: $white;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: all .2s ease-out;
        }
      }
    }
  }
}