.hero {
  background-image: url("/static/images/index/os3/hero-1.jpg");

  button {
    display: block;
    background-color: transparent;
    border:1px solid #ffffff;
    margin-top: 25px;
    &:hover {
      background-color: #c32032;
      border: 1px solid #c32032;
    }
  }
  i {
    margin-right: 10px;
  }
}
