$small-input-height: calc(var(--vs) * 2);
$default-input-height: calc(var(--vs) * 2.4);
$large-input-height: calc(var(--vs) * 3);

.button, button {
  padding: 0 35px;
  margin: 0;
  text-align: center;
  transition: background $easeOut, color $easeOut;
  display: inline-block;
  border: none;
  color: $white;
  background: $red;
  @include f(15px);
  cursor: pointer;
  line-height: $default-input-height;
  font-weight: $bold;
  text-transform: uppercase;

  &:hover {
    background: darken($red, 5%);
    color: $white;
  }

  &.border {
    background: $t;
    border: 2px solid $white;
    color: $white;

    &:hover {
      color: $black;
      background: $white;
    }
  }

  &.loading {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      background: url('../svg/button-loader.svg') no-repeat center center;
      background-size: contain;
    }

    pointer-events: none;
    cursor: wait;
  }

  &.expand, &.wide {
    width: 100%;
  }

  &.height {
    height: $default-input-height;

    &.small {
      padding: 0 calc(var(--vs) * 1.2);
      height: $small-input-height;
      line-height: $small-input-height;

      &:hover {
        color: $black;
      }
    }

    &.large {
      padding: 0 calc(var(--vs) * 3);
      height: $large-input-height;
      line-height: $large-input-height;
    }
  }

}

input {
  &.t {
    background: $t;
  }

  &.border {
    border: 1px solid $greyb8;
  }
}

form {


  label {
    @include f(12px);
    color: #9f9f9f;
    margin-bottom: 2px;
    display: block;
  }

  ul.errors {
    margin-top: 2px;
    list-style: none;
    color: $alert-color;
    @include f(13px);

    li {
      margin-bottom: 2px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  $inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="tel"]';

  #{$inputs}, textarea, select {
    border: 1px solid #c1c1c1;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
    padding: 21px;
    @include f(14px);
    width: 100%;
    display: block;
  }

  #{$inputs}, select {
    height: $default-input-height;
  }

  textarea {
    padding: 5px 10px;
    resize: none;
  }

  .form-field {
    position: relative;
    margin: 10px 0 30px;

    label {
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 21px;
      font-weight: $light;
      @include f(16px);
      color: $black;
      transform: translateY(-50%);
      transition: all $easeOut;
      user-select: none;
      pointer-events: none;
    }

    input:placeholder-shown {
      user-select: none;
    }

    input:not(:placeholder-shown) + label,
    input:focus + label {
      top: -10px;
      left: 0;
      user-select: initial;
      pointer-events: all;
    }

    .errors {

    }
  }
}

@mixin inputTransparentBorder() {
  input {
    background: $t;
    border: 1px solid $greyb8;
  }
}

@mixin dontAnimateLabel() {
  label {
    cursor: text;
    pointer-events: all;
  }
  input:not(:placeholder-shown) + label,
  input:focus + label {
    top: 50%;
    left: 21px;
    transform: translateY(-50%);
  }
  input:not(:placeholder-shown) + label {
    color: $t;
    transition: none;
  }
}


form {
  position: relative;

  &.success {
    .form-field, button {
      opacity: 0;
      pointer-events: none;
    }

    &:before {
      content: "Спасибо! Ваша заявка успешно отправлена.";
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: $h4-size;
      color: $black;
    }
  }

}