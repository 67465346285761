.what {
  padding: calc(#{$vs} * 5) 0;

  h2 {
    display: block;
    width: 100%;
    margin-bottom: calc(#{$vh} * 2);
    text-align: center;
    @include respond-down(sm){
      margin-bottom: $vh;
    }
  }

  .text {
    margin: 0 0 $vh;

    .row {
      display: flex;
      justify-content: center;
      @include respond-down(sm) {
        flex-direction: column;
      }
    }

    .left {
      font-weight: $semi;
      @include f(18px);
      color: $black;
      line-height: calc(30 / 18);
    }

    .right {
      font-weight: $light;
      @include f(16px);
      color: rgba($black, .6);
      line-height: calc(30 / 16);
      @include respond-down(sm){
        margin-top: $vs;
      }
    }
  }

  .try {
    display: block;
    width: 50%;
    margin: $vh auto 0;
    font-weight: $medium;
    font-style: italic;
    @include f(24px);
    color: $black;
    text-align: center;
    line-height: calc(35 / 24);
    @include respond-down(sm){
      width: 100%;
      max-width: 260px;
    }
  }
}