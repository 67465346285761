.interface {

  .aspect.one-to-one:before {
    padding-top: 100%;
  }

  p {
    font-weight: 400;
  }
}
