.partners {
  padding: calc(#{$vh} * 2) 0 calc(#{$vh} * 2 + #{$vs}) 0;

  .text {
    font-weight: $semi;
    @include f(30px);
    color: $black;
    line-height: calc(45 / 30);
  }

  .choose-company {
    display: block;
    width: 100%;
    text-align: right;
    margin-right: 3px;
    font-weight: $bold;
    @include f(20px);
    color: $black;
    @include respond-down(md){
      margin: calc(#{$vs} * 1) 0 0;
    }
  }

  .form {
    display: flex;
    margin: calc(#{$vs} * 5) 0 0;
    align-items: center;
    justify-content: space-between;
    @include respond-down(md){
      margin: calc(#{$vs} * 3) 0 0;
    }
    @include respond-down(sm) {
      flex-direction: column;
    }

    .form-field {
      width: 25%;
      margin: 0;
      @include inputTransparentBorder;
      @include respond-down(sm) {
        width: 100%;
        margin-bottom: 18px;
      }
    }

    .button {
      white-space: nowrap;
      width: calc(#{$vh} * 3 + #{$vs} * 2);
      @include respond-down(sm) {
        width: 100%;
      }
    }
  }
}