.footer {
  display: block;
  padding: calc(#{$vs} * 3) 0;
  background: $black;

  .list {
    display: block;
    position: relative;
    @include respond-down(sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .list-item {
      display: inline-block;
      margin-right: calc(#{$vs} * 2);

      a {
        color: $white;
        @include f(14px);
      }

      &.phone {
        @include respond-up(md) {
          position: absolute;
          right: 0;
          margin-left: auto;
        }
        @include respond-down(sm) {
          grid-column-start: 2;
        }

        a {
          text-align: right;
          font-weight: $bold;
          @include f(15px);
        }


      }


    }
  }
}