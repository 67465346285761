.questions {

  .background {
    color: #fff;
    background-position: center center;
    background-size: cover;
  }

  .masonry-desc {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 75px;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.5));
    padding: 15px;
  }

  a.c4btn.block {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .placeholder-wrapper .placeholder-text {
    position: absolute;
    top: 0;
    left: 16px;
    opacity: 0;
    color: #6e6b6f;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: 11px;
    z-index: 2;
    transition: opacity 0.1s;
  }
  .sr-only {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }


}