.additions {


  *{
    position: relative;
  }

  .row {
    padding: calc(#{$vs} * 4) 0;
    @include respond-down(sm) {
      padding: calc(#{$vs} * 2) 0;
    }

    &:not(:last-of-type) {
      margin: 0 auto calc(#{$vs} * 5);
      @include respond-down(sm) {
        margin: 0 auto calc(#{$vs});
      }
    }
  }

  h2 {
    strong {
      font-weight: 700;
    }

  }


  .background {
    background-size: cover;
    color: #fff;
  }

  .masonry-desc {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 75px;
    background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.5));
    padding: 15px;
  }


}