.btn-all{
  button {
    color: #000;
    border: 1px solid #000;
    background-color: transparent;
    font-weight: 400;
    &:hover {
      color: #fff;
      background: #C42032;
      border: 1px solid #C42032;
    }
  }

  .hidden.active {
    display: block;
  }

  .aspect:before {
    content: "";
    display: block;
    overflow: hidden;
  }

  .one-to-one {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
  }

  .aspect.one-to-one:before {
    padding-top: 100%;
  }
}