.product {
  h2 {
    font-size: 27px;
  }
  a {
    color: #8a2432;
  }
  circle div.icon {
    display: inline-block;
    height: 75px;
    width: 75px;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #939598;
    border-radius: 50%;
    transition: 0.1s;
  }

  circle:hover div.icon {
    background-color: #c32032;
  }
  .row.no-margin {
    margin:0;
  }
  .icon-name {
    text-transform: uppercase;
    font-size: 10px;
  }


}