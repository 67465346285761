.accordion {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  transition-duration: 800ms;
  min-height: 100vh;
  h2 {
    font-size: 27px;
    text-transform: uppercase;
    line-height: 1.26em;

  }
  .accordion-item {
    cursor: pointer;
    .span-text  {
      font-weight: 500;
      opacity: 0;
    }
    &:hover {
      background-color: rgba(195,32,50,0.8);
      .span-text {
        opacity: 1;
      }
      .container-cross {
        background-color: #ffffff;
      }
      .cross-line {
        background-color: rgba(195,32,50,0.8) ;
        &:after {
          background-color: rgba(195,32,50,0.8);
        }
      }
    }
  }

  &.active {
    .accordion-item h2 {
      transform: rotate(90deg);
      float: left;
      width: 100vh;
      transform-origin: left;
      left: 50%;
      @include respond-down(sm){
        transform: rotate(0deg);
        left:0;
      }
    }

    .accordion-item.active h2 {
      transform: rotate(0deg);
      float: none;
      width: 100%;
      left: 0px;
    }

    .accordion-item.active .padding {
      height: 300px;
    }

    #exit-accordion.hidden {
      display: block;
      top: 100px;
      left: 50%;
      z-index: 2;
      .container-cross, .span-line {
        display: block;
      }
      @include respond-down(sm){
        display: none;
      }
    }

    .container-cross, .span-line, .span-text {
      display: none;
    }

  }



  #exit-accordion {
    transform: rotate(45deg);
    cursor: pointer;
    &:hover {
      .container-cross {
        background-color: #ffffff;
      }
      .cross-line {
        background-color: rgba(195,32,50,0.8) ;
        &:after {
          background-color: rgba(195,32,50,0.8);
        }
      }
    }

  }

  .accordion-item:hover.active h3.hidden {
    display: block;
  }

  .accordion-item.active {
    .hidden {
      display: block;
    }
  }

  .accordion-item {
    flex-grow: 1;
    transition-duration: 800ms;
    z-index: 1;
    &.active {
      flex-grow: 5;
    }
    .padding {
      height: 350px;
      padding: 25px;
      @include respond-down(sm){
        height: auto;
      }
    }
  }
  .accordion-item:not(:last-child) {
    border-right: 1px solid #fff;
    @include respond-down(sm){
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }
  .absolute {
    position: absolute;
    &.bottom {
      bottom: 0;
      @include respond-down(sm){
        bottom: -10px;
      }
    }
  }
  .accordion .absolute.bottom i.fa-plus, #exit-accordion i.fa-times {
    border: 1px solid #FFF;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    padding: 16px 17px;
    margin-right: 12.5px;
    z-index: 2;
  }

  .container-cross {
    border: 1px solid #FFF;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin-right: 12px;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .cross-line {
    display: block;
    position: absolute;
    right: calc(50% - 5px);
    top: 50%;
    height: 1px;
    width: 11px;
    background: #ffffff;
    &:after {
      content: '';
      display: block;
      background: #ffffff;
      height: 1px;
      width: 11px;
      transform: rotate(90deg);
    }
  }

  @include respond-down(sm){
    &.full-screen {
      flex-direction: column;
      min-height: calc(100vh + 100px)!important;
    }
  }

}