.projects {
  padding: calc(#{$vs} * 2) 0 calc(#{$vs} * 5);

  h2 {
    margin: 0 0 calc(#{$vs} + #{$vh});
    max-width: 875px;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      display: block;
      margin: 0 0 calc(#{$vs} * 4 + #{$vh} * 2);
      width: 37.55036261%;
      animation-name: itemShow;
      animation-duration: .3s;
      animation-fill-mode: forwards;

      @include respond-down(sm) {
        width: 100%;
        margin: 0 0 calc(#{$vs} * 3 + #{$vh} * 1);
        &:last-child {
          margin: 0 0 calc(#{$vh} + #{$vs});
        }
      }
      @include respond-up(md) {
        &:nth-child(even) {
          margin: calc(#{$vs} * 4) 0 0;
        }
      }

      .image {
        display: flex;
        height: calc(#{$vs} * 4 + #{$vh} * 5);

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      h4 {
        margin: $vs 0 0;
        a{
          color: #000;
          text-decoration: none;
        }
      }

      .place {
        font-weight: $light;
        @include f(15px);
        color: $black;
        line-height: calc(#{$vs} * 2);
      }
    }
  }

  .button {
    display: block;
    width: calc(#{$vs} * 12);
    margin: 0 auto;
  }

  @keyframes itemShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}